<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <h2>Resellers</h2>
        <div class="side_nav_wrapper" v-if="!isSMSVoiceReports">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/pending'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/pending').catch((err) => {})
              "
            >
              Pending
            </button>
            <button
              class="btn"
              :class="[
                $route.path.includes('/admin/resellers/completed')
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/completed').catch((err) => {})
              "
            >
              Completed
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/declined'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/declined').catch((err) => {})
              "
            >
              Declined
            </button>
          </div>

          <div class="__side_nav">
            <el-divider direction="vertical"></el-divider>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/sms-reports'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.push('/admin/resellers/sms-reports').catch((err) => {})
              "
            >
              SMS/Voice Reports
            </button>
          </div>
        </div>
        <div class="side_nav_wrapper" v-else>
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/pending'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/pending').catch((err) => {})
              "
            >
              Pending
            </button>
            <button
              class="btn"
              :class="[
                $route.path.includes('/admin/resellers/completed')
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/completed').catch((err) => {})
              "
            >
              Completed
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/declined'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/resellers/declined').catch((err) => {})
              "
            >
              Declined
            </button>
          </div>

          <div class="__side_nav">
            <el-divider direction="vertical"></el-divider>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/sms-reports'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.push('/admin/resellers/sms-reports').catch((err) => {})
              "
            >
              SMS Reports
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/resellers/voice-reports'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.push('/admin/resellers/voice-reports').catch((err) => {})
              "
            >
              Voice Reports
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  export default {
    computed: {
      isSMSVoiceReports() {
        return (
          this.$route.path.includes("/admin/resellers/sms-reports") ||
          this.$route.path.includes("/admin/resellers/voice-reports")
        );
      },
    },
  };
</script>

<style scoped></style>
